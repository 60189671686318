
<template>
  <div class="page-customer">
    <div class="card card-custom card-stretch gutter-b customer-container">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header></t-farmon-table-header>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-0">
        <!--begin::customer-type-->
        <b-row class="customer-type">
          <template v-for="(item, i) in list">
            <b-col :key="i" sm="12" lg="4">
              <div class="card card-custom card-stretch gutter-b" v-bind:key="i">
                <!--begin::Header-->
                <div class="card-header bg-primary align-items-center border-0">
                  <h3 class="card-title">
                    <span class="title text-white">
                      {{ item.title }}
                    </span>
                  </h3>

                  <div class="card-toolbar">
                    <span class="symbol symbol-30 symbol-primary">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-xl svg-icon-white">
                          <inline-svg :src="item.svg" />
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body py-6">
                  <div class="d-flex align-items-center font-size-lg">
                    <span class="svg-icon svg-icon-xl svg-icon-dark mr-4">
                      <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Call.svg" />
                    </span>
                    <span class="text">{{ item.call }}</span>
                  </div>
                  <div class="d-flex align-items-center font-size-lg mt-4">
                    <span class="svg-icon svg-icon-xl svg-icon-dark mr-4">
                      <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Mail.svg" />
                    </span>
                    <span class="text">{{ item.email }}</span>
                  </div>
                </div>
                <!--end::Body-->
              </div>
            </b-col>
          </template>
        </b-row>
        <!--end::customer-type-->
        <!--begin::customer-form-->
        <div class="customer-form">
          <div class="form-header py-6">
            <h5 class="font-size-h4">{{i18n.consultationRequest}}</h5>
          </div>
          <b-form-group>
            <b-row>
              <!--begin::등록자-->
              <b-col sm="12" lg="6" class="mb-6">
                <div class="d-flex">
                  <label class="form-label">
                    <span class="text font-size-h4 font-weight-bold">{{i18n.registrant}}</span>
                  </label>
                  <div class="form-input flex-fill pr-0 pr-lg-20">
                    <b-form-input size="lg" type="text" required :value="myNm" disabled></b-form-input>
                  </div>
                </div>
              </b-col>
              <!--end::등록자-->
              <!--begin::등록 일시-->
              <b-col sm="12" lg="6" class="mb-6">
                <div class="d-flex">
                  <label class="form-label">
                    <span class="text font-size-h4 font-weight-bold">{{i18n.registeredDate}}</span>
                  </label>
                  <div class="form-input flex-fill">
                    <b-form-input v-model="nowTime" size="lg" type="text" required placeholder="" disabled></b-form-input>
                  </div>
                </div>
              </b-col>
              <!--end::등록 일시-->
              <!--begin::회사 이름-->
              <b-col sm="12" lg="6" class="mb-6">
                <div class="d-flex">
                  <label class="form-label">
                    <span class="text font-size-h4 font-weight-bold">{{i18n.coporateName}}</span>
                  </label>
                  <div class="form-input flex-fill pr-0 pr-lg-20">
                    <b-form-input size="lg" type="text" required :value="companyNm" disabled></b-form-input>
                  </div>
                </div>
              </b-col>
              <!--end::회사 이름-->
              <!--begin::팜이름-->
              <b-col sm="12" lg="6" class="mb-6">
                <div class="d-flex">
                  <label class="form-label">
                    <span class="text font-size-h4 font-weight-bold">{{i18n.farmName}}</span>
                  </label>
                  <div class="form-input flex-fill">
                    <b-form-input id="inputD" size="lg" type="text" :value="farmNm" disabled></b-form-input>
                  </div>
                </div>
              </b-col>
              <!--end::팜이름-->
              <!--begin::문의 내용-->
              <b-col sm="12" class="mb-6">
                <div class="d-flex">
                  <label class="form-label">
                    <span class="text font-size-h4 font-weight-bold">{{i18n.inquiryContent}}</span>
                  </label>
                  <div class="form-input flex-fill">
                    <b-form-textarea
                      v-model="content"
                      :placeholder="`${i18n.inquiryContentPlaceholder}`"
                      rows="5"
                      max-rows="8"
                      size="lg"
                      class="farm-form-control"
                    >서비스를 추가로 설치하려고 하는데 편할 때 연락 주세요!!</b-form-textarea>
                  </div>
                </div>
              </b-col>
              <!--end::문의 내용-->
            </b-row>
          </b-form-group>
        </div>
        <!--end::customer-form-->
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button variant="primary" size="lg" @click="insertCustomer" v-bind:class="{'spinner spinner-light spinner-right': loaded}">
          <span class="svg-icon svg-icon-xl svg-icon-white">
            <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Send.svg" />
          </span>
          <span class="text">{{i18n.send}}</span>
        </b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import TFarmonTableHeader from '@/components/TFarmonTableHeader';
import {mapGetters} from 'vuex';
import {getResult, isSuccess, todayToDate} from '@/core/services/funcs';
import {ACT_INSERT_CUSTOMER} from '@/core/services/variable';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "customer",
  components: {
    TFarmonTableHeader
  },
  beforeMount() {
    this.nowTime = todayToDate();
  },
  computed: {
    ...mapGetters(["companyNm", "farmNm", "myNm"])
  },
  data() {
    return {
      i18n:{
        consultationRequest: i18n.t('faq.consultationRequest'),
        search: i18n.t('faq.search'),
        inquiry: i18n.t('faq.inquiry'),
        searchPlaceholder: i18n.t('faq.searchPlaceholder'),
        customerService: i18n.t('faq.customerService'),
        partnershipInquiry: i18n.t('faq.partnershipInquiry'),
        serviceIncidentInquiry: i18n.t('faq.serviceIncidentInquiry'),
        registrant: i18n.t('faq.registrant'),
        registeredDate: i18n.t('faq.registeredDate'),
        coporateName: i18n.t('faq.coporateName'),
        farmName: i18n.t('faq.farmName'),
        inquiryContent: i18n.t('faq.inquiryContent'),
        inquiryContentPlaceholder: i18n.t('faq.inquiryContentPlaceholder'),
        send: i18n.t('faq.send'),
        useQuickFaq: i18n.t('faq.useQuickFaq'),
        inquiryrequest: i18n.t('faq.inquiryrequest'),

      },
      nowTime: '',
      list: [
        {
          title: i18n.t('faq.customerService'),
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Devices/Headphones.svg",
          call: "070-7776-1142",
          email: "tst@farm8.co.kr"
        },
        {
          title: i18n.t('faq.partnershipInquiry'),
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Group.svg",
          call: "070-7776-1142",
          email: "tst@farm8.co.kr"
        },
        {
          title: i18n.t('faq.serviceIncidentInquiry'),
          svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Code/Info-circle.svg",
          call: "070-7776-1142",
          email: "pm@farm8.co.kr"
        }
      ],
      content: '',
      loaded: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('faq.inquiry'), icon: 'flaticon2-chat' }]);
    window.scrollTo(0,0);
  },
  methods: {
    insertCustomer() {
      if(this.loaded) return;

      if(!this.content || !this.content.trim()){
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('faq.inquiryContentPlaceholder')});
        return;
      }

      this.loaded = true;

      const yesCallback = () => {
        this.$store.dispatch(ACT_INSERT_CUSTOMER, {content: this.content})
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('faq.inquiryrequest'), color: 'success'});
            // 성공시 페이지 이동
            this.content = ''
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
          }
          this.loaded = false
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('faq.error')});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: i18n.t('faq.inquiryAsk')},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });

    }
  }
};
</script>
